import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



class CustomMvp extends React.Component {


  render() {

    return (
      <section className="pt-100 secureof">
         <div className='gray-bg'>
            <div className="container">
            <h2 className="main-heading"><span className='bluecolor'>Custom MVP Software </span>Development Services</h2>
            <p className='text-center'>Our MVP development services help startups and enterprises define the core functionalities, technical expertise, and support system while creating an MVP. Here are the privileges we provide for MVP Software Development.</p>
            <div className="row ">
                <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
                    <div class="custom-card">
                        <StaticImage 
                                src="https://coinsclone.mo.cloudinary.net/images/mvp/startup.webp"
                                alt="Startup"
                                width={321}
                            />
                            <h3>For Startups</h3>
                            <p className='pharagraph'>As a startup, are you looking for an ideal place to test your MVP ideas? Explore the right directives with our refined MVP development services to level up your expertise and market knowledge. Our experts will prepare a cost-effective roadmap to achieve your ideology with our MVP software solution efficiently. With streamlined development processes, we provide you with a space to improvise your business exposure.</p>
                    </div>
                    <div class="custom-card">
                        <StaticImage 
                                src="https://coinsclone.mo.cloudinary.net/images/mvp/enterprises.webp"
                                alt="Enterprises"
                                width={370}
                            />
                            <h3>For Enterprises</h3>
                            <p className='pharagraph'>As a renowned enterprise, you may like to keep your brand name recognized in the new business. As a prominent MVP software development company, Coinsclone provides customized MVP solutions that address the requirements and help you stay in the progressive line. Thus our MVP development services help you kickstart your entrepreneurship thriving against risks and challenges.</p>
                    </div>
                </div>
            </div>
            </div>
         </div>
      </section>
    )
  }
}

export default CustomMvp