import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhatMake extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="bluecolor">What Makes The </span>Coinsclone Best For Buying MVP Products?</h2>
            </div>
          </div>
          <div className="row table-content">
          <div className="col-lg-6 col-md-12">
              <p className='pharagraph'>
              Coinsclone is a top-ranking MVP development service provider with an in-house team of blockchain experts experienced in MVP software development services. We provide MVP solutions that are future-ready, tech-friendly, and easily adaptable to your business requirements. Our professional team is always keen on market movements and suggests appropriate guidance to keep your MVP products ahead of market competition. Also, our client-friendly approach will enable you to have transparent and customized MVP development services.
              </p>
              <p className='pharagraph'>
              At Coinsclone, we prefer emerging technologies that can be refined with our technical expertise to perfectly meet our client’s business strategies. Also, we utilize innovative tech stacks and tools to test your business hypothesis. We create market-ready Minimum Viable Products without compromising our commitments and work quality at any cost. Meanwhile, our MVP development services are cost-effective and help you drive a decent return on investment comprehensively. 
              </p>
            </div>
            <div className="col-lg-6 col-md-12 text-center" >
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/mvp/what-makes.webp"
                alt="best buying mvp produts"
                placeholder='none'
                className='mt-3'
                width={578}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhatMake