import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">  
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 left-side mb-0 mb-md-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>MVP </span>Development Services</h1>
                <p className='pharagraph'>Discover the benefits of high-quality and streamlined MVP software development services that greatly emphasize the value of your Minimum Viable Product. As a renowned MVP software development company, we validate your idea, plan for the necessities, and guide you through insightful development procedures based on your requirements.</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Schedule a Call</a>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/mvp/mvp-software-development-services.webp"
                alt="MVP Software development services"
                className='d-none d-md-block float-end'
                width={479}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection