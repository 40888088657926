import React from 'react'

class TechStack extends React.Component {


  render() {
    return (
      <div className='pt-100'>
      <section className="exchange-stack">
        <div className="container">
          <div className="row table-content">
            <div className="col-md-12 col-lg-6 pr-lg-5">
              <h3 className="heading-h2"><span className='bluecolor'>Tech Stack</span> Behind our MVP Software Development</h3>
              <p className="pharagraph">Explore the dynamic tech stack that thrives our MVP Software development. The Engine of Innovation!
              </p>
            </div>
            <div className="col-md-12 col-lg-6">
              <img className="logostck lazyload" width="572px" height="269px" src="https://coinsclone.mo.cloudinary.net/images/whitelabel/technologies-stock.svg" alt="Technologies stock" />
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default TechStack             