import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class HireOur extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className='gray-bg'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="bluecolor">Hire Our Developers </span>On The Spot</h2>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center" >
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/mvp/hire-developer.webp"
                alt="best ieo development company"
                placeholder='none'
                className='mt-3'
                width={471}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className='pharagraph'>
              Take a lead role by hiring our professional blockchain developers who have expertise in MVP Development services. Our team spirit never compromises on the development quality, however brings innovation through our MVP software development services. With greater market knowledge and access to advanced technology tools, we provide comprehensive solutions for all your MVP development needs.
              </p>
              <p className='pharagraph'>
              Our MVP development services will bring outstanding results and closely work with you to meet your enterprise goals. Also, they have been working on different MVP software development projects and are proficient in yielding better productivity.  Thus you will have endurable support, on-time project completion, and ensure enhanced profit flow in your MVP product.
              </p>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default HireOur