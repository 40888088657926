import React from 'react'



class DevProcess extends React.Component {


  render() {

    return (
      <section className="customs lookout pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h2 className="heading-h2 text-center">Look Out For Our MVP Software <span className="bluecolor">Development Process</span></h2>
            <p className='text-center'>Our MVP Development services maintain a balance between traditional and modern MVP software development. Take a closer look at the tactics and processes that are specifically designed to meet the diverse requirements of potential investors.</p>
            <div className='d-flex flex-wrap justify-content-center top'>
                <div className='custom-blk'>
                    <h3>Ideation</h3>
                    <p class="pharagraph">Our project team will analyze your business goals and future expansion to establish custom MVP software development. The internal ideation process involves competitor and market analysis to regulate the technical capabilities.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Planning</h3>
                    <p class="pharagraph">Based on the roadmap, our experts will plan the tech stacks, and resources to begin MVP software development services. After analyzing market conditions, we plan for a development schedule to complete MVP development on time.</p>
                </div>
                <div className='custom-blk'> 
                    <h3>Design</h3>
                    <p class="pharagraph">During the design phase, our trained MVP developers prepare the UI/UX design to meet industry standards and trends. Our MVP Software Development Services prioritize easy navigation, refined functionality, and quicker accessibility.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Development</h3>
                    <p class="pharagraph">MVP software development is a crucial task where we design and transform your ideas into a product. We prefer innovative tech stacks that hold your business promises and are capable of supporting future upgrades.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Testing</h3>
                    <p class="pharagraph">Our testing experts cross-check every feature and functionality of the final products. This may accelerate MVP software development services to ensure that the MVP product is reliable and adheres to regulations.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Delivery</h3>
                    <p class="pharagraph">The final MVP product is now completely validated for security, performance, and regulatory standards. Our development team and test engineers will analyze and prepare the prerequisites to launch the Minimum Viable Product.</p>
                </div>
            </div>
        </div>
      </div>
    </section>
    )
  }
}

export default DevProcess